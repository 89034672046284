<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="8">
          <b-card-group>
            <b-card no-body class="p-4">
              <b-card-body v-if="!busy.init">
                <b-form>
                  <h1>로그인</h1>
                  <p class="text-muted">계정정보를 입력하여 로그인하세요</p>
                  <b-input-group class="mb-3">
                    <b-input-group-prepend>
                      <b-input-group-text><i class="icon-user"></i></b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input type="text" class="form-control" v-model="form.id" placeholder="ID" autocomplete="id" :readonly="readonly.login"/>
                  </b-input-group>
                  <b-input-group class="mb-3">
                    <b-input-group-prepend>
                      <b-input-group-text><i class="icon-lock"></i></b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input type="password" class="form-control" v-model="form.pw" placeholder="Password" autocomplete="current-password"
                                  @keypress.enter="login" :readonly="readonly.login"/>
                  </b-input-group>
                  <b-checkbox v-if="IS_LOCAL" class="mb-3" v-model="form.useMFA"><b-badge variant="success">TEST</b-badge> MFA 인증 사용</b-checkbox>
                  <b-row>
                    <b-col class="clearfix" cols="12">
                      <b-button variant="primary" class="px-2" @click="login" :disabled="readonly.login || busy.login">로그인</b-button>
                      <a :href='`${url}`' referrerpolicy="origin">
                        <b-button type="button" variant="google-plus" class="ml-1 btn-brand" style="height: 35px" :disabled="readonly.login || busy.login">
                          <span>Google</span>
                        </b-button>
                      </a>
                      <div class="pull-right" v-if="IS_SERVE">
                        <b-button :variant="port === 3030 ? 'primary' : 'light'" @click="changePort(3030)">Prod</b-button>
                        <b-button :variant="port === 3230 ? 'warning' : 'light'" @click="changePort(3230)">Dev</b-button>
                      </div>
                      <!--<b-button variant="link" class="px-0">패스워드 재설정</b-button>-->
                    </b-col>
                  </b-row>
                </b-form>
                <div class="mt-3" v-if="mfaArea">
                  <div v-if="!has.hasEmail && !has.hasMobile && !has.hasOtp">
                    2차 인증이 가능한 수단이 없습니다. 관리자에게 문의해주세요.
                  </div>
                  <template v-else>
                    <h6>2차 인증을 진행해주세요</h6>
                    <b-form-radio-group class="my-2" v-model="form.type" :disabled="form.codeSended">
                      <b-form-radio v-if="has.hasOtp" value="otp">OTP</b-form-radio>
                      <b-form-radio v-if="has.hasEmail" value="email">Email</b-form-radio>
                      <b-form-radio v-if="has.hasMobile" value="mobile">휴대폰</b-form-radio>
                    </b-form-radio-group>
                    <template v-if="['email', 'mobile'].includes(form.type)">
                      <b-btn v-if="!form.codeSended" variant="success" @click="sendAuthCode">인증코드 발송</b-btn>
                      <b-input-group v-else>
                        <b-input v-model="form.code" @keypress.enter="loginWithMFA" :placeholder="limitSecond ?
                         `${Math.floor(limitSecond / 60)}:${(limitSecond % 60).toString().padStart(2, '0')} 이내로 입력해주세요` :
                          `인증시간이 초과되었습니다`" autocomplete="off"></b-input>
                        <b-input-group-append>
                          <b-button class="" variant="primary" @click="loginWithMFA" :disabled="busy.loginWithMFA || !limitSecond">
                            인증
                            <b-spinner class="mr-1" small v-if="busy.loginWithMFA"></b-spinner>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </template>
                    <template v-else-if="form.type === 'otp'">
                      <b-input-group class="">
                        <b-input-group-prepend><b-input-group-text><i class="icon-lock"></i></b-input-group-text></b-input-group-prepend>
                        <b-form-input maxlength="6" class="form-control" v-model="form.code" placeholder="OTP 코드 입력(6자리)" @keypress.enter="loginWithMFA"/>
                        <b-input-group-append><b-button variant="primary" @click="loginWithMFA">인증</b-button></b-input-group-append>
                      </b-input-group>
                    </template>
                  </template>
                </div>
              </b-card-body>
              <b-card-body v-else class="p-0 text-center">
                <b-spinner variant="primary" style="margin-top: calc(40% - 23px)"></b-spinner>
              </b-card-body>
            </b-card>
            <b-card no-body class="text-white bg-primary py-5 d-md-down-none" style="width:44%">
              <b-card-body class="text-center">
                <div>
                  <h2>등록하기</h2>
                  <p class="mt-4">아래의 등록하기 버튼을 눌러서 가입을 완료하시면 관리자가 승인 이후 이용하실 수 있게 됩니다.</p>
                  <router-link :to="{name:'Register'}">
                    <b-button variant="primary" class="active mt-3">등록하기</b-button>
                  </router-link>
                </div>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import nav from '@/_nav'
import store from '@/store';

const {commit: C} = store;


export default {
  name: 'Login',
  title: '로그인',
  data() {
    return {
      form: {
        id: '',
        pw: '',
        useMFA: true,
        type: '',
        emailAuthCode: '',
        mobileAuthCode: '',
        code: '',
        codeSended: false,
        google: false,
      },
      mfaArea: false,
      has: {
        hasEmail: false,
        hasMobile: false,
        hasOtp: false,
      },
      readonly: {
        login: false,
      },
      limitSecond: 180,
      busy: {
        init: true,
        login: false,
        sendAuthCode: false,
        loginWithMFA: false,
      },
      handle: {
        expireCode: null,
      },
      url: '',
    }
  },
  async created() {
    this.url = this.$api.getHost() + '/user/google';

    if (this.$route.query.code) {
      // 서버에 보내서 로그인 확인
      this.busy.login = true;
      const j = await this.$api.postJson('/user/google/login', {code: this.$route.query.code});
      this.busy.login = false;
      if (!j) {
        this.busy.init = false;
        return;
      }

      if (j.ok === 3) {
        this.busy.init = false;
        this.form.id = j.id;
        this.form.google = true;
        delete j.ok;
        Object.assign(this.has, j);
        this.form.type = this.has.hasOtp ? 'otp' : this.has.hasEmail ? 'email' : this.has.hasMobile ? 'mobile' : '';
        return this.openMFA();
      }

      if (j.ok === 2) {
        await this.$router.replace(`/pages/register?id=${j.user.id}&email=${encodeURIComponent(j.user.email)}&name=${j.user.name}`);
        return;
      }

      this.loginAfter(j);
    } else {
      await this.checkLogin();
    }
  },
  methods: {
    async checkLogin() {
      const j = await this.$api.getJson('/user/checkLogin');
      if (j && j.ok === 1) {
        this.loginAfter(j);
      }
      this.busy.init = false;
    },
    async login() {
      const {id, pw, useMFA} = this.form;
      if (id.length < 4) return this.$utils.alert('ID를 4자 이상으로 입력해주세요');
      if (pw.length < 8) return this.$utils.alert('패스워드를 8자 이상으로 입력해주세요');
      this.busy.login = true;
      const j = await this.$api.postJson('/user/login', {id: id, pw: this.$utils.sha256(pw), useMFA});
      this.busy.login = false;
      if (!j) return;

      if (j.ok === 2) {
        delete j.ok;
        Object.assign(this.has, j);
        this.form.type = this.has.hasOtp ? 'otp' : this.has.hasEmail ? 'email' : this.has.hasMobile ? 'mobile' : '';
        return this.openMFA();
      }

      await this.loginAfter(j);
    },
    async loginAfter(j) {
      // let ru = await (await fetch('https://randomuser.me/api/?seed='+j.user.id)).json();
      // j.user.pic = ru.results[0].picture.large;
      j.user.img = j.user.img || `https://${this.S3_CDN}/img/common/img_profile.png`;
      j.user.roles = this.$utils.expandRole(j.user.role);
      C('login', j.user);

      // meta 최초 로딩
      await this.$api.getAllMeta();

      let firstPage = nav.items.filter(e => e.url && ~j.user.roles.indexOf(e.role))[0]; // 권한에 맞는 최초페이지
      if (!firstPage) firstPage = {url: '/'};
      this.$router.replace(this.$route.query.go || firstPage.url);
    },
    changePort(port) {
      localStorage.setItem('port', port);
      location.reload();
    },
    openMFA() {
      this.mfaArea = true;
      this.readonly.login = true;
    },
    async sendAuthCode() {
      clearInterval(this.handle.expireCode);
      this.busy.sendAuthCode = true;
      const j = await this.$api.postJson('/user/sendAuthCode', {type: this.form.type});
      this.busy.sendAuthCode = false;
      if (j) {
        this.form.codeSended = true;
        this.limitSecond = 180;
        // 입력한도시간 표시
        this.handle.expireCode = setInterval(() => {
          if (--this.limitSecond <= 0) clearInterval(this.handle.expireCode);
        }, 1000);
        this.$utils.alert('인증코드가 발송되었습니다. 확인 후 입력해주세요');
        this.$forceUpdate();
      }
    },
    async loginWithMFA() {
      const {id, type, code, google} = this.form;
      this.busy.loginWithMFA = true;
      const j = await this.$api.postJson('/user/loginWithMFA', {id, type, code, google});
      this.busy.loginWithMFA = false;
      if (!j) return;

      await this.loginAfter(j);
    },
  }
}
</script>
